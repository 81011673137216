nav .active,
.mobile-menu .active {
  color: #f57b20;
}

.mobile-menu .active {
  font-weight: 600;
}

@media (min-width: 768px) {
  nav:hover {
    color: #f57b20;
  }
}
