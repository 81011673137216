.carousel-caption {
  top: 50%;
  transform: translateY(-50%);
  bottom: initial;
}

.item {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
